#fun-fact-with-contact-button{
    margin-left:20px;
    align-content: flex-end;
}

.form-group{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
}

.homepage-full-name{
    margin-right: 20px;
}

.homepage-email{
    margin-right: 20px ;
}

.contact-stacked-status {
    color: #f8966b;
}


button{
    display:'flexp';
    align-items: 'flex-end';
}
@media screen and (max-width: 991px){
    .form-group{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: stretch;
    } 
    .cs-form_field{
        min-width: 100%;

    }
    .homepage-full-name{
       
        width:100% !important;
        margin-right: 0;
        padding:0 !important;
    }
    .homepage-email{
        margin-top: 15px !important;
        width:100% !important;
        margin-right: 0 ;
        padding-left: 0 !important;
    }
   
    button{
        margin-top:20px !important;
        width: 100% !important;
        margin-left: 0 !important;
    }
}